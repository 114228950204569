<template>
  <DefaultLayout>
    <FloatingBox class="sm:w-128">
      <template v-if="state === 'success'">
        <Alert :alert="{
        type: 'success',
        title: $t('verify.verified.title'),
        text: $t('verify.verified.text'),
        }" />
      </template>
      <template v-if="state === 'invalid'">
        <Alert :alert="{
        type: 'error',
        title: $t(`verify.error.${error}-title`),
        text: $t(`verify.error.${error}`),
        }" />
      </template>
      <template  v-else-if="state === 'ready'">
        <form-fieldset :legend="$t('verify.title')">
          <form-input autofocus :label="$t('verify.otp')" v-model="otp" :placeholder="$t('verify.placeholder')" />
        </form-fieldset>

        <div class="rounded border border-red-500 bg-red-100 text-red-700 p-2 m-2 mb-4" v-if="error">
          <p>{{$t(`verify.error.${error}`)}}</p>
        </div>

        <div class="p-2">
          <Button :disabled="loading" class="text-dark" @click="resend">{{$t('verify.resend')}}</Button>
          <Button :disabled="loading" class="float-right text-brand bg-dark" @click="verify">{{$t('verify.submit')}}</Button>
        </div>
      </template>
    </FloatingBox>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '../layouts/Default.vue'
import FloatingBox from '../components/FloatingBox'
import FormFieldset from '../components/Forms/FormFieldset.vue';
import FormInput from '../components/Forms/FormInput.vue';
import Button from '../components/Button.vue';
import Alert from '../components/Alert.vue'

import api from '../api';

export default {
  name: 'Search',
  components: {
    DefaultLayout,
    FloatingBox,
    Alert,
    FormFieldset,
    FormInput,
    Button
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      error: null,
      state: null,
      loading: false,
      otp: null,
      verified: false,
    }
  },
  async created() {
    this.fetch()
  },
  computed: {
  },
  methods: {
    async fetch() {
      try {
        await api.get(`verify/${this.token}`)
        this.state = 'ready'
      } catch ({response: {status}}) {
        this.state = 'invalid'
        this.error = {
          404: 'not-found',
          403: 'not-ready',
          410: 'already-verified',
        }[status] || 'unknown'
      }
    },
    async verify() {
      this.loading = true
      this.error = null

      try {
        await api.post(`verify/${this.token}`,{otp: this.otp})
        this.state = 'success'
      } catch({response: {status}}) {
        this.error = {
          400: 'wrong-otp',
          404: 'not-found',
        }[status] || 'unknown'
      } finally {
        this.loading = false   
      }
    },
    async resend() {
      this.loading = true
      this.error = null

      try {
        const {data} = await api.patch(`verify/${this.token}`)
        console.log(data)
      } catch({response: {status}}) {
        this.error = {
          429: 'throttle',
          404: 'not-found',
        }[status] || 'unknown'
      } finally {
        this.loading = false   
      }
    }
  },
  metaInfo() {
    return {
      title: this.$t('verify.title'),
    }
  } 
};
</script>
