var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',[_c('FloatingBox',{staticClass:"sm:w-128"},[(_vm.state === 'success')?[_c('Alert',{attrs:{"alert":{
      type: 'success',
      title: _vm.$t('verify.verified.title'),
      text: _vm.$t('verify.verified.text'),
      }}})]:_vm._e(),(_vm.state === 'invalid')?[_c('Alert',{attrs:{"alert":{
      type: 'error',
      title: _vm.$t(("verify.error." + _vm.error + "-title")),
      text: _vm.$t(("verify.error." + _vm.error)),
      }}})]:(_vm.state === 'ready')?[_c('form-fieldset',{attrs:{"legend":_vm.$t('verify.title')}},[_c('form-input',{attrs:{"autofocus":"","label":_vm.$t('verify.otp'),"placeholder":_vm.$t('verify.placeholder')},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}})],1),(_vm.error)?_c('div',{staticClass:"rounded border border-red-500 bg-red-100 text-red-700 p-2 m-2 mb-4"},[_c('p',[_vm._v(_vm._s(_vm.$t(("verify.error." + _vm.error))))])]):_vm._e(),_c('div',{staticClass:"p-2"},[_c('Button',{staticClass:"text-dark",attrs:{"disabled":_vm.loading},on:{"click":_vm.resend}},[_vm._v(_vm._s(_vm.$t('verify.resend')))]),_c('Button',{staticClass:"float-right text-brand bg-dark",attrs:{"disabled":_vm.loading},on:{"click":_vm.verify}},[_vm._v(_vm._s(_vm.$t('verify.submit')))])],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }